import { Component, OnInit, Input } from '@angular/core';
import { Category } from '@models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.sass']
})
export class CategoryFormComponent implements OnInit {

  @Input() category: Category;
  label: FormControl;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.label = new FormControl(null, Validators.required);
    this.label.setValue(this.category.label);
  }

  submit(): void {
    this.category.label = this.label.value;
    this.activeModal.close(this.category);
  }

}
