import { Component, OnInit, Input } from '@angular/core';
import { Channel } from '@models';
import { Observable, iif, EMPTY } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-channel-avatar',
  templateUrl: './channel-avatar.component.html',
  styleUrls: ['./channel-avatar.component.sass']
})
export class ChannelAvatarComponent implements OnInit {

  @Input() channelUID: string;
  channel$: Observable<Channel>;

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.channel$ = iif(() => !!this.channelUID,
      this.db.doc<Channel>(`Channels/${this.channelUID}`).valueChanges(),
      EMPTY);
  }

}
