import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { Router } from '@angular/router';
import { environment } from '@env';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})
export class AuthComponent implements OnInit {

  user: User;
  isBeta = !environment.production;
  constructor(private afAuth: AngularFireAuth,
              private router: Router) { }

  ngOnInit() {
    this.afAuth.user.subscribe(data => {
      this.user = data;
      if (this.user) {
        this.router.navigate(['dashboard']);
      }
    });
  }

}
