export class Mosque {
    id: string;
    placeId: string;
    name: string;
    formattedAddress: string;
    icon?: string;
    url?: string;

    constructor(id: string,
                placeId: string,
                name: string,
                formattedAddress: string,
                icon: string = '',
                url: string = '') {
        this.id = id;
        this.placeId = placeId;
        this.name = name;
        this.formattedAddress = formattedAddress;
        this.icon = icon;
        this.url = url;
    }
}