export class Category {
    uid?: string;
    label: string;
    photoUrl?: string;
    dorossNbr = 0;
    playlistsNbr = 0;

    constructor(label?: string) {
        this.label = label;
    }
}