import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Dars, Channel } from '@models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { trigger, transition, style, animate, query, group } from '@angular/animations';
import { EditLabelComponent } from './edit-label/edit-label.component';

@Component({
  selector: 'app-dars-form',
  templateUrl: './dars-form.component.html',
  styleUrls: ['./dars-form.component.sass'],
  animations: [
    trigger('extensionsContent', [
      transition('* => *', [
        group([
          query('div:enter', [
            style({ marginTop: '-10px', opacity: 0 }),
            animate('300ms ease', style({ marginTop: 0, opacity: 1 }))
          ], { optional: true })
        ])
      ]),
    ]),
    trigger('incrementAnimation', [
      transition(':decrement', [style({ opacity: 0 }), animate('300ms ease', style({ opacity: 1 }))]),
      transition(':increment, :enter', [style({ transform: 'scale(0.5)' }), animate('300ms ease', style({ transform: 'scale(1)' }))]),
      transition(':leave', [style({transform: 'scale(1)', opacity: 1}), 
                            animate('300ms ease', style({ transform: 'scale(0)', opacity: 0 }))])
    ])
  ]
})
export class DarsFormComponent implements OnInit {

  @Input() dars: Dars;
  @ViewChild(EditLabelComponent, { static: true }) darsLabel: EditLabelComponent;
  channel$: Observable<Channel>;
  selectedExt: number = undefined;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.dars.uid) { console.log(this.dars.uid); }
  }

  submit() {
    this.activeModal.close(this.dars);
  }

  selectExt(i: number) {
    this.selectedExt = i;
  }

  updateDarsInfo(dars: Dars) {
    this.darsLabel.updateData(dars);
  }


}
