import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent as ImageCropper } from 'ngx-image-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.sass']
})
export class ImageCropperComponent implements OnInit {

  @ViewChild(ImageCropper, { static: true }) imageCropper: ImageCropper;

  @Input() imageChangedEvent: any = '';
  @Input() ratio = 1 / 1;
  croppedImage: any = '';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
    console.log('Cropped ready called');
  }
  loadImageFailed() {
    // show message
  }

  cropImage() {
    const event = this.imageCropper.crop();
    const file = this.dataURLtoFile(event.base64, 'fileName');
    this.activeModal.close(file);
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

}
