

export class UserProfile {
    uid?: string;
    displayName?: string;
    email?: string;
    joinAt?: Date;
    lastSeen?: Date;
    photoUrl?: string;
    provider?: string;
    role?: string;
    author?: boolean;
    admin?: boolean;
    Channels?: ChannelRole[];
    disabled?: boolean;
    userRequests?: UserRequest[];

    constructor() {}
}


interface ChannelRole {
    ChannelUID: string;
    UserUID: string;
    permission: PERMISSION;
}

export interface UserRequest {
    userUID: string;
    displayName?: string;
    seen: boolean;
    subject: string;
    createdAt: Date;
}

export enum PERMISSION {
    guest,
    author,
    admin,
    owner
}