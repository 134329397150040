import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Dars } from '@models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-edit-label',
  templateUrl: './edit-label.component.html',
  styleUrls: ['./../dars-form.component.sass'],
  animations: []
})
export class EditLabelComponent implements OnInit {

  @Input() dars: Dars;
  @Output() darsChange: EventEmitter<Dars> = new EventEmitter<Dars>();
  rForm: FormGroup;

  constructor(private fb: FormBuilder,
              private db: AngularFirestore) { }

  ngOnInit() {
    this.createForm();
    if (this.dars.uid) {
      this.db.doc(`Doross/${this.dars.uid}/Details/${this.dars.uid}`).get()
      .subscribe(snapshot => {
        this.dars.description = snapshot.get('description');
        this.patchForm();
      });
    }
    this.rForm.valueChanges.subscribe(() => {
      this.dars.title = this.rForm.get('title').value;
      this.dars.description = this.rForm.get('description').value;
      this.darsChange.emit(this.dars);
    });
  }

  createForm() {
    this.rForm = this.fb.group({
      title: [null, Validators.required],
      description: [null],
    }, { updateOn: 'blur' });
  }

  updateData(dars: Dars) {
    this.rForm.patchValue(dars);
  }

  patchForm() {
    this.rForm.patchValue(this.dars);
  }

}
