import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  rForm: FormGroup;
  errorMessage = '';
  wrongPassword = false;
  isLoading = false;

  constructor(private fb: FormBuilder,
              private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.createForm();
    this.rForm.valueChanges.subscribe(() => {
      this.errorMessage = null;
      this.wrongPassword = false;
    });
  }

  createForm() {
    this.rForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  submit() {
    this.errorMessage = null;
    this.isLoading = true;
    const email = this.rForm.get('username').value;
    const password = this.rForm.get('password').value;
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(() => this.isLoading = false)
      .catch((error) => {
        // Handle Errors here.
        this.isLoading = false;
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          this.wrongPassword = true;
        } else {
          this.errorMessage = errorMessage;
        }
      });
  }

  googleLogin() {
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  facebookLogin() {
    this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
  }

}
