import {Playlist } from './Playlist';
import { Dars } from './Dars';
import { UserProfile } from './UsersProfiles';
import { Mosque } from './Mosque';

export class Channel {
    uid?: string;
    title?: string;
    description?: string;
    website?: string;
    coverImageUrl?: string;
    coverImageThumbnailUrl?: string;
    photoUrl?: string;
    photoThumbnailUrl?: string;
    adminUID?: string;
    admins?: string[];
    followersNbr = 0;
    followers?: UserProfile[];
    likes = 0;
    dislikes = 0;
    playlists?: Playlist[];
    playlistsNbr?: number;
    doross?: Dars[];
    dorossNbr = 0;
    status = 'private'; // 'public' || 'private'
    mosque: Mosque;

    constructor() {}
}