import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { Dars } from '../../models/Dars';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { PlayerService } from '../../player.service';
import { Observable } from 'rxjs';
import { FormsService } from 'src/app/channels/forms.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Playlist } from '../../models/Playlist';

@Component({
  selector: 'app-dars-item',
  templateUrl: './dars-item.component.html',
  styleUrls: ['./dars-item.component.sass'],
  animations: [
    trigger('collapseAnimation', [
      transition(':enter', [
        style({ marginTop: '-100%', opacity: 0 }),
        animate('300ms ease', style({ marginTop: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ marginTop: '0', opacity: 1 }),
        animate('300ms ease', style({ marginTop: '-100%', opacity: 0 }))
      ]),
    ])
  ]
})

export class DarsItemComponent implements OnInit {

  @Input() dars: Dars;
  @Input() showAuthorAvatar = true;
  @Input() showPlaylistSubtitle = true;
  @Input() selected = false;
  @Input() showOrder = false;
  @Input() playlist: Playlist;
  @Output() titleSelect = new EventEmitter<Dars>();
  @Output() moveToLast = new EventEmitter<Dars>();
  @Output() moveToFirst = new EventEmitter<Dars>();
  playedDars$: Observable<Dars>;
  isAdmin$: Observable<boolean>;

  
  constructor(private db: AngularFirestore,
              public playerService: PlayerService,
              private forms: FormsService,
              private route: ActivatedRoute,
              private fns: AngularFireFunctions) { }

  ngOnInit() {
    this.playedDars$ = this.playerService.getCurrentDars();

    this.isAdmin$ = this.route.queryParams.pipe(
      map(params => (params.admin === 'true'))
    );
  }

  getAudioDuration(duration: number) {
    this.dars.recordDuration = duration;
  }

  selectTitle() {
    this.titleSelect.emit(this.dars);
  }

  deleteDars() {
    if (confirm('Would you really like to delete this dars??')) {
      return this.db.doc(`Doross/${this.dars.uid}`).delete();
    }
  }

  editDars() {
    this.forms.openDarsForm(this.dars).then(data => {
      return this.db.doc(`Doross/${this.dars.uid}`).update(data);
    }, reason => {});
  }

  publishDars() {
    if (this.playlist.status === 'public') {
      this.db.doc(`Doross/${this.dars.uid}`).update({ status: 'published' });
    }
  }

  makePrivate() {
    this.db.doc(`Doross/${this.dars.uid}`).update({ status: 'private' });
  }

  generateAudioWave() {
    if (!this.dars.uid) { return; }
    const addAudioWave = this.fns.httpsCallable('fixingFunctions-generateAudioWave');
    addAudioWave({ darsUID: this.dars.uid }).
      subscribe((data) => {
        // this.loading = false;
        console.log(data);
      }, (err) => {
        // this.loading = false;
        console.log(err);
      }, () => {
        // this.loading = false;
      });
  }

  moveDarsToLast() {
    this.moveToLast.emit(this.dars);
  }

  moveDarsToFirst() {
    this.moveToFirst.emit(this.dars);
  }

}
