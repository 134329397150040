import { Directive, HostListener, Host, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[dropFile]'
})
export class DropFileDirective {

  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHovered = new EventEmitter();

  constructor() { }

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    const transfer = $event.dataTransfer;
    this.filesDropped.emit(transfer.files);
    this.filesHovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    // $event.preventDefault();
    this.filesHovered.emit(false);
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    const input = document.getElementById('upload-file-input');
    if (!input) { return false; }
    input.click();
  }


}
