import { Playlist } from './Playlist';
import { Channel } from './Channel';
import { Category } from './Category';
import { Mosque } from './Mosque';


export class Dars {
    uid?: string;
    title?: string;
    description?: string;
    files?: File[];
    PlaylistUID?: string;
    ChannelUID?: string;
    status?: string; // ['draft', 'private', 'published']
    recordUrl?: string;
    recordSize?: number;
    recordDuration?: number;
    createdAt?: Date = new Date();
    author?: any;
    authorUID?: string;
    playlist?: Partial<Playlist>;
    channel?: Partial<Channel>;
    orderInPlaylist?: number;
    categories: Category[] = [];
    mosque?: Mosque;
    buffer?: number[];

    constructor(title?: string,
                description?: string,
                files?: File[],
                playlistUID?: string,
                channelUID?: string,
                status?: string) {
        this.title = title || '';
        this.description = description || '';
        this.files = files || [];
        this.PlaylistUID = playlistUID || null;
        this.ChannelUID = channelUID || null;
        this.status = status || 'published';
        this.orderInPlaylist = 1;
    }
}