import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StaticsRoutingModule } from './statics-routing.module';
import { StaticsComponent } from './statics.component';
import { PrivacyComponent } from './privacy/privacy.component';


@NgModule({
  declarations: [StaticsComponent, PrivacyComponent],
  imports: [
    CommonModule,
    StaticsRoutingModule
  ],
  exports: [
    StaticsComponent
  ]
})
export class StaticsModule { }
