
/**
 * Transform url by addeding dimensions postfix to met with firebase imageResize extension
 * @param originalUrl -- Url of the uploaded file
 * @param dimensions -- selected dimension of the resized image in forme: '500x500'
 */
export function fixUrl(originalUrl: string, dimensions?: string): string {
    if (!dimensions) { return originalUrl; }
    if (originalUrl.includes(`_${dimensions}`)) { return originalUrl; }
    // Find last index of character '?': start of query url
    const index = originalUrl.lastIndexOf('?');
    return `${originalUrl.substring(0, index)}_${dimensions}${originalUrl.substring(index)}`;
}