import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../../player.service';
import { Observable } from 'rxjs';
import { Dars } from '../../models/Dars';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.sass']
})
export class PlayerComponent implements OnInit {

  playedDars$: Observable<Dars>;

  constructor(public playerService: PlayerService) { }

  ngOnInit() {
    this.playedDars$ = this.playerService.getCurrentDars();
  }

}
