import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.sass']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() options = {
    text: 'Are you sure to make this action?',
    positiveAnswer: 'OK',
    negativeAnswer: 'Cancel',
    confirmationText: undefined
  };
  confirmationText: FormControl;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.confirmationText = new FormControl(null,
        Validators.compose([Validators.required, Validators.pattern(`${this.options.confirmationText}`)]));
  }

}
