import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Channel, Playlist, Dars, Category } from '@models';
import { ChannelFormComponent } from './forms/channel-form/channel-form.component';
import { PlaylistFormComponent } from './forms/playlist-form/playlist-form.component';
import { DarsFormComponent } from './forms/dars-form/dars-form.component';
import { CategoryFormComponent } from './forms/category-form/category-form.component';

@Injectable()
export class FormsService {

  ngbModalRef: NgbModalRef;

  constructor(private ngbModal: NgbModal) { }

  openChannelForm(channel: Channel) {
    this.ngbModalRef = this.ngbModal.open(ChannelFormComponent);
    this.ngbModalRef.componentInstance.channel = channel;
    return this.ngbModalRef.result;
  }

  openPlaylistForm(playlist: Playlist) {
    this.ngbModalRef = this.ngbModal.open(PlaylistFormComponent);
    this.ngbModalRef.componentInstance.playlist = playlist;
    return this.ngbModalRef.result;
  }

  openDarsForm(dars: Dars): Promise<Dars> {
    this.ngbModalRef = this.ngbModal.open(DarsFormComponent);
    this.ngbModalRef.componentInstance.dars = dars;
    return this.ngbModalRef.result;
  }

  openCategoryForm(category: Category): Promise<Category> {
    this.ngbModalRef = this.ngbModal.open(CategoryFormComponent);
    this.ngbModalRef.componentInstance.category = category;
    return this.ngbModalRef.result;
  }
} 
