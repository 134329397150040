import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { } from 'googlemaps';
import { Mosque } from '@models';

@Component({
  selector: 'app-search-mosque',
  templateUrl: './search-mosque.component.html',
  styleUrls: ['./search-mosque.component.sass']
})
export class SearchMosqueComponent implements OnInit, AfterViewInit {

  @Output() selectMosque: EventEmitter<Mosque> = new EventEmitter();
  @ViewChild('addresstext', { static: true }) addresstext: any;
  autocomplete: string;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {

    this.getPosition().then(position => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      const circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
      const options = {
        types: ['establishment'],
        fields: ['name', 'id', 'place_id', 'url', 'formatted_address', 'icon'],
        bounds: circle.getBounds()
      };
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, options);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.invokeEvent(place);
      });
    });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve(resp);
        }, err => reject(err));
      } else {
        reject('geolocation not supported...');
      }
    });
  }

  invokeEvent(place: any) {
    this.selectMosque.emit(new Mosque(
      place.id,
      place.place_id,
      place.name,
      place.formatted_address,
      place.icon,
      place.url
    ));
    // this.dars.mosque = place;
    // this.darsChanges.emit(this.dars);
    // this.editMode = false;
  }

}
