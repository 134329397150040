import { Component, OnInit, Input } from '@angular/core';
import { Playlist } from '@models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-playlist-form',
  templateUrl: './playlist-form.component.html',
  styleUrls: ['./playlist-form.component.sass']
})
export class PlaylistFormComponent implements OnInit {

  @Input() playlist: Playlist;
  rForm: FormGroup;
  private playlistDoc: AngularFirestoreDocument<Playlist>;
  // private playlist: Observable<Playlist>;

  constructor(private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private db: AngularFirestore) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.rForm = this.fb.group({
      title: [null, Validators.required],
      description: [null]
    });
  }

  patchForm() {
    this.rForm.patchValue(this.playlist);
  }

  submit() {
    const data = this.rForm.value;
    this.playlist.title = data.title;
    this.playlist.description = data.description;
    return this.activeModal.close(this.playlist);
  }

}
