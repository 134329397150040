import { Dars } from './Dars';
import { UserProfile } from '@models';

export class Playlist {
    uid?: string;
    title?: string;
    description?: string;
    ChannelUID?: string;
    doross?: Dars[];
    adminUID?: string;
    admins?: string[];
    dorossNbr?: number;
    coverImageUrl?: string;
    coverImageThumbnailUrl?: string;
    status?: string; // 'public' || 'private'
    followersNbr?: number;
    followers?: UserProfile[];
    likes?: number;
    dislikes?: number;
    createdAt?: Date;
    updatedAt?: Date;
    origin?: string;

    constructor(channelUID: string) {
        this.ChannelUID = channelUID;
        this.status = 'private';
    }
}