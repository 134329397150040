import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {

  rForm: FormGroup;
  errorMessage = '';

  constructor(private fb: FormBuilder,
              private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.createForm();
    this.rForm.valueChanges.subscribe(() => {
      this.errorMessage = '';
    })
  }

  createForm() {
    this.rForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      password_confirm: [null, Validators.required]
    });
  }

  get passwordsMatch() {
    return this.rForm.get('password').touched &&
            (this.rForm.get('password').value === this.rForm.get('password_confirm').value)
  }

  submit() {
    this.errorMessage = '';
    const email = this.rForm.get('email').value;
    const password = this.rForm.get('password').value;
    this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      if (errorCode === 'auth/weak-password') {
        this.errorMessage = 'The password is too weak.';
      } else {
        // alert(errorMessage);
        this.errorMessage = error.message;
      }
      console.log(this.errorMessage);
    });
  }

}
