import { Component, OnInit, Input } from '@angular/core';
import { Upload } from '@models';

@Component({
  selector: 'app-uploading-dars',
  templateUrl: './uploading-dars.component.html',
  styleUrls: ['./uploading-dars.component.sass']
})
export class UploadingDarsComponent implements OnInit {

  @Input() currentUpload: Upload;
  
  constructor() { }

  ngOnInit() {
  }

}
