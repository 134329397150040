import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserProfile } from '@models';
import { filter, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';

/**
 * Fetch user from backend and display avatar and name as reusable component
 * [userUID]="userUID"
 * [size]="'small'" >> ['small', 'medium', 'large']
 */

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.sass']
})
export class UserAvatarComponent implements OnInit, OnChanges {

  @Input() userUID: string;
  @Input() size = 'small';
  @Input() avatarOnly = false;
  @Input() title: string = null;
  user$: Observable<UserProfile>;
  userChanged = new BehaviorSubject<UserProfile>(null);

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.user$ = this.userChanged.pipe(
      distinctUntilChanged(),
      filter(data => !!data),
      switchMap(userUID => this.db.doc<UserProfile>(`Users/${userUID}`).valueChanges()),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userUID.currentValue !== changes.userUID.previousValue) {
      this.userChanged.next(changes.userUID.currentValue);
    }
  }

}
