import { Component, OnInit, Input } from '@angular/core';
import { Channel, Mosque } from '@models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.sass']
})
export class ChannelFormComponent implements OnInit {

  @Input() channel: Channel;
  rForm: FormGroup;
  imagePath: string;
  imgURL: any;

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.rForm = this.fb.group({
      title: [null, Validators.required],
      description: null,
      website: null,
      mosque: this.fb.group({
        id: [null],
        placeId: [null],
        name: [null],
        formattedAddress: [null],
        icon: [null],
        url: [null]
      })
    }, {
      updateOn: 'blur'
    });
  }

  selectMosque(mosque: Mosque) {
    if (!!mosque) {
      this.rForm.get('mosque.id').setValue(mosque.id);
      this.rForm.get('mosque.placeId').setValue(mosque.placeId);
      this.rForm.get('mosque.name').setValue(mosque.name);
      this.rForm.get('mosque.formattedAddress').setValue(mosque.formattedAddress);
      this.rForm.get('mosque.icon').setValue(mosque.icon);
      this.rForm.get('mosque.url').setValue(mosque.url);
    }
  }

  patchForm() {
    this.rForm.patchValue(this.channel);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }


  submit() {
    this.activeModal.close(this.rForm.value);
  }

}
