import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatSidenavModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatProgressBarModule,
  MatTableModule
} from '@angular/material';



import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSpinKitModule } from 'ng-spin-kit';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';

import { DropFileDirective } from './drop-file.directive';
import { ChannelAvatarComponent } from './components/channel-avatar/channel-avatar.component';
import { DarsItemComponent } from './components/dars-item/dars-item.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { UploadingDarsComponent } from './components/uploading-dars/uploading-dars.component';

import { environment } from '@env';
import { DurationPipe } from './pipes/duration.pipe';
import { PlayerComponent } from './components/player/player.component';
import { SearchMosqueComponent } from './components/search-mosque/search-mosque.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';


@NgModule({
  declarations: [
    DropFileDirective,
    ChannelAvatarComponent,
    DarsItemComponent,
    UserAvatarComponent,
    ConfirmationModalComponent,
    UploadingDarsComponent,
    DurationPipe,
    PlayerComponent,
    SearchMosqueComponent,
    ImageCropperComponent,
    ShortNumberPipe
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgbModule,
    RouterModule,
    NgxFilesizeModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatButtonModule,
    ImageCropperModule
  ],
  exports: [
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,

    NgSpinKitModule,
    NgbModule,
    NgxFilesizeModule,
    ColorPickerModule,
    // Material modules
    MatSidenavModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    DropFileDirective,
    DragDropModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    // Shared Components
    ChannelAvatarComponent,
    DarsItemComponent,
    UserAvatarComponent,
    UploadingDarsComponent,
    PlayerComponent,
    // Pipes
    DurationPipe,
    ShortNumberPipe,
    SearchMosqueComponent
  ],
  providers: [
    // AngularFireAuthGuard
  ],
  entryComponents: [
    ConfirmationModalComponent,
    ImageCropperComponent
  ]
})
export class SharedModule { }
